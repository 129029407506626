class SectionsMenu {
    constructor() {
        this.menu = $(".o-sections-menu, .section-scrollbar")[0];

        if (!this.menu) return;

        this.items = this.menu.querySelectorAll("li");
        this.links = this.menu.querySelectorAll(".o-sections-menu__link");
        this.itemsMap = {};
        this.currentSectionId = "empty";

        this.items.forEach((item) => {
            const sectionId = item.querySelector("a").dataset.scrollTo;
            this.itemsMap[sectionId] = item;
        });

        this.init();
    }

    init() {
        this.toggleFixedOnScroll();
        this.detectCurrentSection();
    }

    toggleFixedOnScroll() {
        document.addEventListener("scroll", () => {
            if (this.menu.getBoundingClientRect().top <= 63) {
                this.menu.classList.add("fixed");
                this.links.forEach((link) => link.classList.add("fixed"));
            } else {
                this.menu.classList.remove("fixed");
                this.links.forEach((link) => link.classList.remove("fixed"));
            }
        });
    }

    detectCurrentSection() {
        this.onScroll();
        document.addEventListener("scroll", () => this.onScroll());
    }

    findClosestSection() {
        let foundSection = document
            .elementFromPoint(100, window.innerHeight * 0.4)
            .closest("section");

        if (!foundSection || !this.itemsMap[foundSection.id]) {
            foundSection = document
                .elementFromPoint(100, window.innerHeight * 0.6)
                .closest("section");
        }

        if (!foundSection || !this.itemsMap[foundSection.id]) {
            foundSection = document
                .elementFromPoint(100, window.innerHeight * 0.2)
                .closest("section");
        }

        return foundSection;
    }

    onScroll() {
        const currentSection = this.findClosestSection();

        if (!currentSection) {
            this.items.forEach((item) => item.classList.remove("current"));
            this.currentSectionId = "empty";
            return;
        }

        if (
            this.itemsMap[currentSection.id] &&
            this.currentSectionId != currentSection.id
        ) {
            if (this.currentSectionId == "empty") {
                this.currentSectionId = currentSection.id;
            }
            this.itemsMap[this.currentSectionId].classList.remove("current");
            this.currentSectionId = currentSection.id;
            this.itemsMap[currentSection.id].classList.add("current");
        }
    }
}

export { SectionsMenu };
