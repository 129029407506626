export const clickViaUrlParam = () => {
    // fire click via url param "show"
    const urlParams = new URLSearchParams(window.location.search);
    const showIt = urlParams.has('show');
    if( showIt ){
        const showItID = urlParams.get('show');
        const clickMe = document.getElementById( showItID );
        const clickMeParent = $("#" + showItID).parent();
        if( clickMe ){
            clickMe.click();
            $([document.documentElement, document.body]).animate({scrollTop: clickMeParent.offset().top - 80}, 2500);
        }
    }
}