const trigerClientsMenu = () => {
  const elemCat = $("[data-elem-name]");
  const elemCatChild = $("[data-elemchild-name]");
  var queryParams = new URLSearchParams(window.location.search);    
  var attr1;
  var attr2;
  $(elemCat).each(function () {
      var self = $(this);
      self.parent().on("click", function (e) {
          attr1 = self.attr("data-elem-name");   
          queryParams.set("cat", attr1);  
          queryParams.delete("post");   
          
          history.replaceState(null, null, "?"+queryParams.toString());
      });
  });

  $(elemCatChild).each(function () {
      var self = $(this);
      self.parent().on("click", function (e) {
          attr2 = self.attr("data-elemchild-name");
          queryParams.set("post", attr2);
          history.replaceState(null, null, "?"+queryParams.toString());
      });
  })
  var getUrlParameter = function getUrlParameter(sParam) {
      var sPageURL = window.location.search.substring(1),
          sURLVariables = sPageURL.split('&'),
          sParameterName,
          i;

      for (i = 0; i < sURLVariables.length; i++) {
          sParameterName = sURLVariables[i].split('=');

          if (sParameterName[0] === sParam) {
              return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
          }
      }
      return false;
  };
  if (getUrlParameter('cat') && getUrlParameter('post')) {
      var parCat = getUrlParameter('cat');
      var parPost = getUrlParameter('post');

      setTimeout(function () { 
          $("[data-elem-name='" + parCat + "']").trigger("click");
          $("[data-elemchild-name='" + parPost + "']").trigger("click");           


      }, 10);
      setTimeout(function () { 
          queryParams.set("cat", parCat);  
          queryParams.set("post", parPost);   
          history.replaceState(null, null, "?"+queryParams.toString());
      }, 40);
    
  }
  else if (getUrlParameter('cat')) {
      setTimeout(function () {
          $("[data-elem-name='" + getUrlParameter('cat') + "']").trigger("click");
      }, 10);
  }

}
export default trigerClientsMenu;