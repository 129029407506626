import "../vendors/slick.js";

class Slider {
	constructor(elem, conf = {}, point = 0) {
		this.config = {
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			variableWidth: true,
			mobileFirst: true,
			useTransform: false,
			responsive: [
				{
					breakpoint: 1199,
					settings: "unslick",
			},
			],
			...conf,
		};
		this.mqLg   = window.matchMedia( "(max-width: 1200px)" );
		this.elem   = $( elem );
		this.init();
		this.reInitit();
	}
	init() {
		this.elem.length > 0 && this.elem.slick( this.config );
	}

	reInitit() {
		this.mqLg.addListener(
			() => {
				if (this.mqLg.matches) {
					this.elem.length > 0 &&
					this.elem[0] &&
					this.elem[0].slick.refresh();
				}
			}
		);
	}
}
class SliderMD {
	constructor(elem, conf = {}, point = 0) {
		this.config = {
			slidesToScroll: 1,
			dots: true,
			arrows: false,
			variableWidth: true,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 766,
					settings: "unslick",
			},
			],
			...conf,
		};
		this.mqLg   = window.matchMedia( "(max-width: 767px)" );
		this.elem   = $( elem );
		this.init();
		this.reInitit();
	}
	init() {
		this.elem.length > 0 && this.elem.slick( this.config );
	}

	reInitit() {
		this.mqLg.addListener(
			() => {
				if (this.mqLg.matches) {
					this.elem.length > 0 &&
					this.elem[0] &&
					this.elem[0].slick.refresh();
				}
			}
		);
	}
}

export { Slider, SliderMD };
