export const calculatorWaas = () => {
    const section = document.querySelector('.wass-calulator');

    if (!section) {
        return;
    }

    const inputChangeMonthProduct = section.querySelectorAll('.js-change-month-product');
    const inputsChangeProductValue = section.querySelectorAll('.js-change-qty-product');
    const spanProductValue = section.querySelectorAll('.js-price-product');
    let monthProduct = 24;
    const countPackets = section.querySelector('.js-wass-laptop-count');
    const pricePackets = section.querySelector('.js-wass-laptop-price');

    let client = true;
    const inputChangeMsCount = section.querySelectorAll('.js-change-qty-msc');
    const inputsChangeMonthsMsc = section.querySelectorAll('.js-change-month-msc');
    const inputClients = section.querySelectorAll('.switch-label-table');
    const inputMonthMsc = section.querySelector('.js-result-month-msc');
    const inputsPacketsMsc = section.querySelectorAll('.js-result-qty-msc');
    const totalCountMsc = section.querySelector('.js-wass-msc-count');
    const totalPriceMsc = section.querySelector('.js-wass-msc-price');

    const inputChangeCyberPacket = section.querySelectorAll('.js-change-packet-cyber');


    const waasTotalCount = section.querySelector('.js-result-count-waas');
    const waasTotalmonth = section.querySelector('.js-result-month-waas');
    const waasTotalprice = section.querySelector('.js-result-month-price-waas');
    const cyberTotalmonth = section.querySelector('.js-result-month-cyber');
    const mscTotalmonth = section.querySelector('.js-result-month-ms');
    const mscTotalCount = section.querySelector('.js-result-count-ms');
    const cyberTotalCount = section.querySelector('.js-result-count-cyber');
    const mscTotalPrice = section.querySelector('.js-result-month-price-ms');

    const caluclatePriceProduct = () => {
        let totalSummary = 0;
        let countTotal = 0;

        for (const item of spanProductValue) {
            const parent = item.closest('.wass-calulator__box');
            const priceWrapper = parent.querySelector('.js-result-qty-product');
            const count = parseInt(priceWrapper.innerText);
            const priceItem = parseInt(priceWrapper.getAttribute('data-price-' + monthProduct));
            const total = parseFloat(count * priceItem);
            item.innerText = total.toFixed(2) + ' zł';

            countTotal = countTotal + count;
            totalSummary = totalSummary + total;
        }

        countPackets.innerText = countTotal;
        waasTotalCount.innerText = countTotal;
        pricePackets.innerText = totalSummary.toFixed(2) + ' zł';
        waasTotalprice.innerText = totalSummary.toFixed(2) + ' zł netto/miesięcznie'
    };

    for (const item of inputsChangeProductValue) {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            const valueWrapper = item.parentNode.querySelector('.js-result-qty-product');
            let valueValue = parseInt(valueWrapper.innerText);
            

            if (item.dataset.type == 'minus') {
                if (valueValue <= parseInt(mscTotalCount.innerText)) {
                    return;
                }

                if (valueValue <= parseInt(cyberTotalCount.innerText)) {
                    return;
                }
                
                if (valueValue <= 0) {
                    return
                }

                valueValue--;
                valueWrapper.innerText = valueValue;
                caluclatePriceProduct();
                return;
            }

            valueValue++;
            valueWrapper.innerText = valueValue;
            caluclatePriceProduct();
        });
    }

    for (const item of inputChangeMonthProduct) {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            const valueWrapper = item.parentNode.querySelector('.js-result-month-product');
            let valueValue = parseInt(valueWrapper.innerText);

            if (item.dataset.type === 'minus') {
                if (valueValue === 24) {
                    return
                }

                valueValue = valueValue - 12;
                valueWrapper.innerText = valueValue;
                monthProduct = valueValue;
                waasTotalmonth.innerText = valueValue + ' miesięcy';
                cyberTotalmonth.innerText = valueValue + ' miesięcy';
                caluclatePriceProduct();
                return;
            }

            if (valueValue === 48) {
                return
            }

            valueValue = valueValue + 12;
            valueWrapper.innerText = valueValue;
            monthProduct = valueValue;
            waasTotalmonth.innerText = valueValue + ' miesięcy';
            cyberTotalmonth.innerText = valueValue + ' miesięcy';
            caluclatePriceProduct();
        });
    }

    //cyber

    const priceCyber = 24.9;
    let countCyber = 0;
    const wrapperCyberPrice = section.querySelector('.js-wass-cyber-price');
    const wrapperTotalCyberPrice = section.querySelector('.js-result-month-price-cyber');

    const caluculateCyberPrice = () => {
        wrapperCyberPrice.innerText = (priceCyber * countCyber).toFixed(2) + ' zł';
        wrapperTotalCyberPrice.innerText = (priceCyber * countCyber).toFixed(2) + ' zł netto/miesięcznie';
    }

    for (const item of inputChangeCyberPacket) {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            const valueWrapper = item.parentNode.querySelector('.js-result-packet-cyber');
            let valueValue = parseInt(valueWrapper.innerText);

            if (item.dataset.type == 'minus') {
                if (valueValue <= 0) {
                    return
                }

                valueValue--;
                valueWrapper.innerText = valueValue;
                cyberTotalCount.innerText = valueValue;
                countCyber = valueValue;
                caluculateCyberPrice();
                return;
            }

            if (parseInt(waasTotalCount.innerText) <= valueValue) {
                return;
            }

            valueValue++;
            valueWrapper.innerText = valueValue;
            cyberTotalCount.innerText = valueValue;
            countCyber = valueValue;
            caluculateCyberPrice();
        });
    }

    // ms
    const caluclatePriceMS = (client) => {
        let price = 0;
        let totalValue = 0;

        if (client) {
            for (const item of inputsPacketsMsc) {
                const singlePrice = item.closest('.wass-calulator__box').querySelector('.js-price-msc');
                totalValue = totalValue + (parseInt(item.innerText));
                price = price + (parseInt(item.innerText) * parseFloat(item.dataset.priceClient));
                singlePrice.innerText = (parseInt(item.innerText) * parseFloat(item.dataset.priceClient)).toFixed(2) + ' zł';
            }
        } else {
            for (const item of inputsPacketsMsc) {
                const singlePrice = item.closest('.wass-calulator__box').querySelector('.js-price-msc');
                totalValue = totalValue + (parseInt(item.innerText));
                price = price + (parseInt(item.innerText) * parseFloat(item.dataset.price));
                singlePrice.innerText = (parseInt(item.innerText) * parseFloat(item.dataset.price)).toFixed(2) + ' zł';
            }
        }


        totalCountMsc.innerText = totalValue;
        totalPriceMsc.innerText = parseFloat(price).toFixed(2) + ' zł';

        mscTotalCount.innerText = totalValue;
        mscTotalPrice.innerText = parseFloat(price).toFixed(2) + ' zł netto/miesięcznie';
    };

    for (const item of inputChangeMsCount) {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            const value = item.parentNode.querySelector('.js-result-qty-msc');
            let valueValue = parseInt(value.innerText);

            if (item.dataset.type == 'minus') {
                if (valueValue <= 0) {
                    return
                }

                valueValue--;
                value.innerText = valueValue;
                caluclatePriceMS(client);
                return;
            }

            if (parseInt(waasTotalCount.innerText) <= parseInt(mscTotalCount.innerText)) {
                return;
            }

            valueValue++;
            value.innerText = valueValue;
            caluclatePriceMS(client);
        });
    }

    for (const item of inputsChangeMonthsMsc) {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            const value = item.parentNode.querySelector('.js-result-month-msc');
            let valueValue = parseInt(value.innerText);

            if (item.dataset.type == 'minus') {
                if (valueValue === 12) {
                    return
                }

                valueValue = valueValue - 12;
                value.innerText = valueValue;
                mscTotalmonth.innerHTML = valueValue + ' miesięcy';
                return;
            }

            if (valueValue === 24) {
                return
            }

            valueValue = valueValue + 12;
            value.innerText = valueValue;
            mscTotalmonth.innerHTML = valueValue + ' miesięcy';
        });
    }


    for (const itemClient of inputClients) {
        itemClient.addEventListener('change', (e) => {
            for (const itemClient of inputClients) {
                itemClient.classList.remove('active');
            }

            itemClient.classList.add('active');

            if (itemClient.checked && itemClient.value === '1') {
                for (const item of inputsChangeMonthsMsc) {
                    item.disabled = false
                }

                client = true;
                caluclatePriceMS(client);
                return;
            }

            inputMonthMsc.innerText = '12';
            mscTotalmonth.innerHTML = '12 miesięcy';
            client = false;
            caluclatePriceMS(client);

            for (const item of inputsChangeMonthsMsc) {
                item.disabled = true;
            }
        });

    }
}

