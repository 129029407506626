export const calculatorMSC = () => {
    const section = document.querySelector('.js-caluclator-msc');

    if (!section) {
        return;
    }

    const inputsChangeValue = section.querySelectorAll('.input-plus-minus__button-ms');
    const inputsChangeMonths = section.querySelectorAll('.input-plus-minus__button-ms-months');
    const inputsValues = section.querySelectorAll('.calculator-csb__microsoft-package');
    const inputMonth = section.querySelector('.input-plus-minus__input-month');
    const summary = section.querySelector('#bottom-calculator__price-total');
    const inputClients = section.querySelectorAll('.switch-label-table');
    const total = section.querySelector('#bottom-calculator__packages-total');
    const singlePrice = section.querySelectorAll('.js-single-table-price');

    let client = true;

    const caluclatePrice = (client) => {
        let price = 0;
        let totalValue = 0;

        if (client) {
            for (const item of inputsValues) {
                totalValue = totalValue + (parseInt(item.value));
                price = price + (parseInt(item.value) * parseFloat(item.dataset.priceClient));
                singlePrice[parseInt(item.dataset.column) - 1].innerText = (parseInt(item.value) * parseFloat(item.dataset.priceClient)).toFixed(2);
            }
        } else {
            for (const item of inputsValues) {
                totalValue = totalValue + (parseInt(item.value));
                price = price + (parseInt(item.value) * parseFloat(item.dataset.price));
                singlePrice[parseInt(item.dataset.column) - 1].innerText = (parseInt(item.value) * parseFloat(item.dataset.price)).toFixed(2);
            }
        }

        total.value = totalValue;
        summary.value = parseFloat(price).toFixed(2);
    };

    for (const item of inputsChangeValue) {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            const value = item.parentNode.querySelector('input');
            let valueValue = parseInt(value.value);

            if (item.dataset.buttonType == 'minus') {
                if (valueValue <= 0) {
                    return
                }

                valueValue--;
                value.value = valueValue;
                caluclatePrice(client);
                return;
            }

            valueValue++;
            value.value = valueValue;
            caluclatePrice(client);
        });
    }

    for (const item of inputsChangeMonths) {
        item.addEventListener('click', (e) => {
            e.preventDefault();
            const value = item.parentNode.querySelector('input');
            let valueValue = parseInt(value.value);

            if (item.dataset.buttonType == 'minus') {
                if (valueValue === 12) {
                    return
                }

                valueValue = valueValue - 12;
                value.value = valueValue;
                return;
            }

            if (valueValue === 24) {
                return
            }

            valueValue = valueValue + 12;
            value.value = valueValue;
        });
    }


    for (const itemClient of inputClients) {
        itemClient.addEventListener('change', (e) => {
            for (const itemClient of inputClients) { 
                itemClient.classList.remove('active');
            }

            itemClient.classList.add('active');

            if (itemClient.checked && itemClient.value === '1') {
                for (const item of inputsChangeMonths) {
                    item.disabled = false
                }

                client = true;
                caluclatePrice(client);
                return;
            }

            inputMonth.value = '12';
            client = false;
            caluclatePrice(client);

            for (const item of inputsChangeMonths) {
                item.disabled = true;
            }
        });

    }
}

