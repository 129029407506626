import "../vendors/malihu.js";

const customScrollBar = () => {
    $("[data-elem='custom-scroll']").length > 0 &&
        $("[data-elem='custom-scroll']").mCustomScrollbar({
            axis: "x", // horizontal scrollbar
        });
};

export default customScrollBar;
