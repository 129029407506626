const get_browser = () => {
    var ua = navigator.userAgent,
        tem,
        M =
            ua.match(
                /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
            ) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
        tem = ua.match(/\bOPR|Edge\/(\d+)/);
        if (tem != null) {
            return { name: "Opera", version: tem[1] };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return {
        name: M[0],
        version: M[1],
    };
};

const BROWSER_NAME = {
    OPERA: "Opera",
    CHROME: "Chrome",
    SAFARI: "Safari",
    IE: "IE",
    FIREFOX: "Firefox",
};

const BROWSER_SUPPORT = {
    OPERA: 69,
    CHROME: 84,
    SAFARI: 12,
    FIREFOX: 83,
};

class BrowserList {
    constructor() {
        this.browser = get_browser();
        this.modal = document.querySelector('[data-js="browserList-js"]');

        this.init();
    }

    openModal() {
        this.modal.classList.add("is-active");
    }

    checkBrowser() {
        switch (this.browser.name) {
            case BROWSER_NAME.FIREFOX:
                if (this.browser.version < BROWSER_SUPPORT.FIREFOX) {
                    this.openModal();
                }
                break;
            case BROWSER_NAME.SAFARI:
                if (this.browser.version < BROWSER_SUPPORT.SAFARI) {
                    this.openModal();
                }
                break;
            case BROWSER_NAME.CHROME:
                if (this.browser.version < BROWSER_SUPPORT.CHROME) {
                    this.openModal();
                }
                break;
            case BROWSER_NAME.OPERA:
                if (this.browser.version < BROWSER_SUPPORT.OPERA) {
                    this.openModal();
                }
                break;
            case BROWSER_NAME.IE:
                this.openModal();
                break;
        }
    }

    init() {
        if (this.modal && document.documentElement.clientWidth > 991) {
            this.checkBrowser();
        }
    }
}

export default BrowserList;

//Close modal window browserslist on old browsers
$(function() {                     
    $(".js-modal-close").click(function() {
      $(".form-modal--browserslist").removeClass("is-active");  
    });
});