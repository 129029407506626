import Filter from "../filter/filter";

class SearchResults {
    constructor() {
        const isSearchResultsPage = document.getElementsByClassName('search-results');

        if (!isSearchResultsPage.length) {
            return;
        }

        this.sections = [];
        this.bindSections();
        this.bindFilter();
    }

    bindSections() {
        const elements = document.querySelectorAll('.search-results__section');

        elements.forEach(element => {
            const params = JSON.parse(element.dataset.params);

            this.sections.push({
                element,
                searchCategory: params.searchCategory,
                isActive: true,
            });
        });
    }

    bindFilter() {
        const element = document.querySelector('.filter');

        new Filter({
            element,
            onChange: (item) => {
                this.sections.map(section => {
                    if (!item.slug) {
                        section.element.classList.remove('isHidden');

                        return;
                    }

                    if (section.searchCategory === item.slug) {
                        section.element.classList.remove('isHidden');
                    } else {
                        section.element.classList.add('isHidden');
                    }
                })
            }
        });
    }
}

export default SearchResults;
