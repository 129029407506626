const pinchZoom = () => {
    var img = $('#pinch-zoom-image');
    var placheholder = $('#pinch-zoom-image-placeholder');
    var img_container = $('#pinch-zoom-container');
   
    const showImage = function () {
        img.addClass("show");
        placheholder.addClass("hide");
    };
    
    const hidePlacheholder = function () {
        img_container.removeClass("inactive");
    };

    img.length > 0 &&
        img.on("click tap", (e) => hidePlacheholder());
    placheholder.length > 0 &&
        placheholder.on("click tap", (e) => showImage());

};
export default pinchZoom;