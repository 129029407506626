import getSortOrder from "../helpers/getSortOrders";


import {
    createContent,
    createEmptyResult,
    createNotEnough
} from "../helpers/createContent";



const ajaxSearch = (wrapper, elem, nonce) => {
    const wrap = wrapper;
    const key = nonce;
    $.ajax({
        url: "/wp-admin/admin-ajax.php",
        type: "post",
        data: {
            action: "data_search",
            keyword: elem.val(),
            key
        },

        success: (req) => {
            if (req == "") {
                wrap.html("");
                elem.removeClass("loading");
                return;
            }
            const arr = JSON.parse(req);
            const sortedArray =
                arr &&
                arr.posts.sort((a) => (a.post_type === "Produkty") ? -1 : 1);

            if(elem.val().length < 2) {
                wrap.html(createNotEnough());
            } else {
                if (arr.posts[0] && arr.posts[0]["post_type"] !== "empty" || arr.sug) {
                    wrap.html(createContent(sortedArray, arr.sug));
                    wrap.find('.suggest-item').on('click', function () {
                       $('.input-search').val($(this).text());
                       ajaxSearch(wrapper, elem, nonce);
                    });
                } else {
                    wrap.html(createEmptyResult());
                } 
            }

            
            elem.removeClass("loading");
        },
        error: (req, status, error) => {
            wrap.html(error);
            elem.removeClass("loading");
        },

    });

};

export default ajaxSearch;