class Filter {
    constructor({element, onChange}) {
        this.element = element;
        this.onChange = onChange;
        this.listEl = this.element.querySelector('.filter-list');

        this.bindButton();
        this.bindItems();
    }

    bindButton() {
        this.button = this.element.querySelector('.filter__button');

        if (!this.button) {
            return;
        }

        this.button.addEventListener('click', () => {
            this.listEl.classList.toggle('isActive');
        })
    }

    bindItems() {
        const elements = this.listEl.querySelectorAll('.filter-item__button');

        if (!elements.length) {
            return;
        }

        elements.forEach(element => {
            element.addEventListener('click', () => {
                const params = JSON.parse(element.dataset.params);

                this.button.innerHTML = params.title;
                this.listEl.classList.remove('isActive');

                this.onChange(params)
            });
        });
    }
}

export default Filter;
