const modal = () => {	
	const elemOpen = $(".js-modal-open");

	elemOpen.each(function () {			
		const dataEL = $(this).attr("data-modal-open");
		const El = $("[data-modal='" + dataEL +"']");
		const ElClose = $("[data-modal-close='" + dataEL +"']");

		$(this).length > 0 &&
			$(this).on("click", (e) => toggle(e, El, true));
		ElClose.on("click", (e) => toggle(e, El, false));
	});
	const toggle = (e, el, state) => {
		e.preventDefault();
		if (state){
			el.addClass("is-active");
			$('body').addClass('open-modal');
		}
		else {
			el.removeClass("is-active");
			$('body').removeClass('open-modal');
		}
	};
};
export default modal;