import ajaxSearch from "./ajax/ajax-search";

const dynamicSearch = () => {
	
	var elem = $(".inputT");
	var delayTimer;
	// elem.on( "input", () => ajaxSearch( wrapper, elem, nonce ) );	
	elem.on('propertychange input', function () {
		elem = $(this);
		const wrapper = $(this).closest('.main-search-form').find('[data-elem=dynamicWrapper]');
		const nonce = $("body").find('input[name="nonce"]').val();
		clearTimeout(delayTimer);	
		$(this).addClass("loading");
		delayTimer = setTimeout(function () {			
			ajaxSearch(wrapper, elem, nonce);	
		}, 500);
	});	
};

export default dynamicSearch;