import { changeCurrentFormDatasets } from "../gtm-datalayer-pushes/gtm-datalayer-pushes";

const toggleNewsletter = () => {
    const newsletterIcon = $("[data-elem=newsletter-icon]");
    const newsletterForm = $(".form-modal--newsletter");
    const body = newsletterForm.find(".form-modal__body");

    newsletterIcon.on('click touchend', function () {
        changeCurrentFormDatasets(newsletterIcon[0].dataset)
        newsletterForm.toggleClass("is-active");
        body.addClass("is-active");
    });
};

const checkIfEmptyInput = () => {
    const checkValue = function (e) {
        if ($(this).val()) {
            $(this).addClass("not-empty");
        } else {
            $(this).removeClass("not-empty");
        }
    };

    $("input").on('change keyup keydown', checkValue);
    $("textarea").on('change keyup keydown', checkValue);
};

const telephonyForm = () => {
    const formModal = $(".form-modal--telephony");
    const forms = $(".form-modal__body--telephony");
    const menuButtons = $(".form-modal__item");

    menuButtons.click(function (e) {
        forms.each(function () {
            $(this).removeClass("is-active");
        });
        menuButtons.each(function () {
            $(this).removeClass("is-active");
        });
        $(this).addClass("is-active");
        const formID = $(this)
            .attr("class")
            .split(/\s+/)
            .filter((className) => className.includes("telephony"))[0];
        $("div." + formID).addClass("is-active");
    });

    const btn = $(
        "[data-elem=show-telephony-form]"
    );
    btn.click(function (e) {
        e.preventDefault();
        formModal.addClass("is-active");
    });
};

export {
    checkIfEmptyInput,
    toggleNewsletter,
    telephonyForm,
};
