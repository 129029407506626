const elemBubble = $( "[data-elem='newsletter-bubble']" );
const elem       = elemBubble.closest( "[data-elem='newsletter-icon']" );

const toggle = (e, state) => {
	e.preventDefault();
	state
		? elemBubble.addClass( "isActive" )
		: elemBubble.removeClass( "isActive" );
};

const newsletterIcon = () => {
	$( elem ).length > 0 &&
		$( elem ).on( "mouseenter touchstart", (e) => toggle( e, true ) );
	$( elem ).on( "mouseleave touchend", (e) => toggle( e, false ) );
};
export default newsletterIcon;
