import { changeCurrentFormDatasets } from "../gtm-datalayer-pushes/gtm-datalayer-pushes";

const ACTION_TYPE = {
    SEND_MESSAGE: "send-message",
    LEAVE_NUMBER: "leave-number",
    LEAVE_NUMBER_HUB: "leave-number-hub",
    GET_NUMBER: "get-number",
    BASIC_2a: "basic-2a",
    BASIC_2b: "basic-2b",
    STEP_2b: "step-2b",
};

const toggleBtnText = (button) => {
    let buttonText = button.textContent.trim();
    let buttonTextAfter = button.getAttribute("data-after").trim();
    [buttonText, buttonTextAfter] = [buttonTextAfter, buttonText];
    button.textContent = buttonText;
    button.setAttribute("data-after", buttonTextAfter);
};

const getPageNameFromUrl = () => {
    const urlParts = window.location.href.split("/");
    const length = urlParts.length;

    const pageName = (
        urlParts[length - 1] != "" ? urlParts[length - 1] : urlParts[length - 2]
    )
        .split("?")[0]
        .split("&")[0]
        .replace(/-/g, " ")
        .replace(/#/g, "");

    return pageName;
};

const clientsModals = (wrapper = document) => {
    const triggers = wrapper.querySelectorAll("[data-action]");

    const sendMessageHandler = () => {
        const numberForm = $(".form-modal--step2b");
        const numberBody = $(".form-modal__body--number");
        const finished = $(".form-modal__body--finished");
        numberForm.addClass("is-active");
        numberBody.addClass("is-active");
        finished.removeClass("is-active");
    };

    const leaveNumberHandler = () => {
        const numberForm = $(".form-modal--eservice-number");
        const numberBody = $(".form-modal__body--number");
        const finished = $(".form-modal__body--finished");
        numberForm.addClass("is-active");
        numberBody.addClass("is-active");
        finished.removeClass("is-active");
    };

    const leaveNumberHubHandler = () => {
        const numberForm = $(".form-modal--hub");
        const numberBody = $(".form-modal--hub .form-modal__body--number");
        const finished = $(".form-modal--hub .form-modal__body--finished");
        numberForm.addClass("is-active");
        numberBody.addClass("is-active");
        finished.removeClass("is-active");
    };
    // open if url "kontakt" param is set
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const contact = urlParams.has('kontakt');
    if( contact ){
        leaveNumberHandler();
    }
    
    const basic2a = (evt) => {
        const modalWithSelect = $(".form-modal.modal-with-select");
        const step2 = $("#step-2");
        const step3 = $("#step-3");
        modalWithSelect.addClass("is-active");
        step2.addClass("is-active");
        step3.removeClass("is-active");
    };
    const basic2b = (evt) => {
        const modalWithSelect = $(".form-modal.modal-with-select");
        const step3 = $("#step-3");
        const step2 = $("#step-2");
        modalWithSelect.addClass("is-active");
        step3.addClass("is-active");
        step2.removeClass("is-active");
    };
    const step2b = (evt) => {
        const numberForm = $(".form-modal--step2b");
        const numberBody = $(".form-modal__body--number");
        const finished = $(".form-modal__body--finished");
        numberForm.addClass("is-active");
        numberBody.addClass("is-active");
        finished.removeClass("is-active");
    };
    const getNumberHandler = (evt) => {
        const target = evt.target;
        const banner = target.closest(".js-banner");
        toggleBtnText(target);

        const infoBlock = banner.querySelector(".js-banner-info");
        infoBlock.classList.toggle("c-banner__info--show");
    };

    const addListeners = (trigger) => {
        const actionType = trigger.getAttribute("data-action");
        let handler;
        switch (actionType) {
            case ACTION_TYPE.SEND_MESSAGE:
                handler = sendMessageHandler;
                break;
            case ACTION_TYPE.LEAVE_NUMBER:
                handler = leaveNumberHandler;
                break;
            case ACTION_TYPE.LEAVE_NUMBER_HUB:
                handler = leaveNumberHubHandler;
                break;
            case ACTION_TYPE.GET_NUMBER:
                handler = getNumberHandler;
                break;
            case ACTION_TYPE.STEP_2b:
                handler = step2b;
                break;
            case ACTION_TYPE.BASIC_2a:
                handler = basic2a;
                break;
            case ACTION_TYPE.BASIC_2b:
                handler = basic2b;
                break;
        }
        trigger.addEventListener("click", (evt) => {
            evt.preventDefault();
            handler(evt);
            if (trigger.dataset.formName == "" && getPageNameFromUrl() != "kontakt")
                trigger.dataset.formName = getPageNameFromUrl();
            if (actionType != ACTION_TYPE.GET_NUMBER)
                changeCurrentFormDatasets(trigger.dataset);
        });
    };

    const init = () => {
        triggers.forEach((trigger) => {
            if (trigger) {
                addListeners(trigger);
            }
        });
    };

    init();

    const serviceInputs = () => {
        if ($(".js-service").length > 0) {
            const callQuestion = $("#service_call_tag");
            const callHeader = $("#service_call_header");
            const messageQuestion = $("#service_message_tag");
            const messageHeader = $("#service_message_header");
            const formHeader = $(".lqt-form-header");

            $(".js-service").on("click", function () {
                const questionForm = $(this).attr("data-question");
                const titleForm = $(this).attr("data-title");
                formHeader.html(titleForm);

                if ($(this).attr("data-action") == "leave-number") {
                    callQuestion.val(questionForm);
                    callHeader.val(titleForm);
                } else {
                    messageQuestion.val(questionForm);
                    messageHeader.val(titleForm);
                }
            });

            $(".js-modal-close").on("click", function () {
                callQuestion.val("");
                callHeader.val("");
                messageQuestion.val("");
                messageHeader.val("");
                formHeader.html("");
            });
        }
    };

    serviceInputs();
};

export default clientsModals;
