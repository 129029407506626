class BoxElement {
    constructor() {
        this.param = "eserwis";
        this.boxElements = document.querySelectorAll("[data-box]");

        this.backUrl =
            "https://ecare.t-mobile.pl/eserwis/new/product-portfolio";
            // "https://preprod.ecare.t-mobile.pl/eserwis/new/product-portfolio";
        this.contactUrl = (id) =>
            // `https://preprod.ecare.t-mobile.pl/eserwis/new/add-lead?productID=${id}`;
            `https://ecare.t-mobile.pl/eserwis/new/add-lead?productID=${id}`;

        this.buttonWrapperSelector = ".o-section-box-element__buttons-wrapper";
        this.buttonsSelector = "[data-action=leave-number]";

        this.init();
    }

    getQueryParams() {
        const searchParamsString = location.search.replace("?", "");
        const searchParams = searchParamsString.split("&");
        const params = searchParams.map((item) => {
            const [param, value] = item.split("=");
            return { param, value };
        });

        return params;
    }

    replaceButtons() {
        const buttons = document.querySelectorAll("[data-action=leave-number]");
        buttons.forEach((button) => {
            button.replaceWith(button.cloneNode(true));
        });
    }

    nextStep() {
        this.productId = this.boxElements[0].dataset.box;
        this.replaceButtons();
        this.buttons = document.querySelectorAll("[data-action=leave-number]");

        this.buttons.forEach((button) => {
            button.addEventListener("click", (evt) => {
                evt.preventDefault();
                this.postHandler();
            });
        });

        this.boxElements.forEach((element) => {
            element.classList.add("logged");
            this.addButtonsEvents(element);
        });
    }

    imitateLink(href) {
        const a = document.createElement("a");
        a.href = href;
        a.click();
    }

    postHandler() {
        const urlWithId = this.contactUrl(this.productId);
        this.imitateLink(urlWithId);
    }

    backHandler() {
        this.imitateLink(this.backUrl);
    }

    addButtonsEvents(element) {
        const links = element.querySelectorAll(
            this.buttonWrapperSelector + " a"
        );

        links.forEach((link) => {
            link.addEventListener("click", (evt) => {
                evt.preventDefault();
                if (link.dataset.act === "post") {
                    this.postHandler();
                }
                if (link.dataset.act === "back") {
                    this.backHandler();
                }
            });
        });
    }

    init() {
        // const opa = window.location.pathname;
        const cookie_eserwis = 'eserwis_logged';

        if (!this.boxElements.length) {
            return;
        }

        const neededParam = this.getQueryParams().find(
            (param) => param.param === this.param
        );
        
        if (neededParam) {
            this.nextStep();
            // Cookies.set('___eserwis', opa, { path: '' })
            Cookies.set('__eserwis', 'eserwis_logged');
        }
       
        if (cookie_eserwis == Cookies.get("__eserwis")) {
            this.nextStep();
        }
    }
}

export default BoxElement;
