const step4 = $('#step-4')
const eServiceEmailForm = $('.form-modal__body--email')
const eServiceNumberForm = $('.form-modal__body--number')
const productForm = $('.form-modal__body--product')
const finishedForm = $('.form-modal__body--finished')

var checkSelect = function (input) {
  if (input.val() == '') {
    input.addClass('error')
    return false
  } else {
    input.removeClass('error')
    return true
  }
}

var checkEmail = function (input) {
  var email = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
  if (input.val().match(email)) {
    input.removeClass('error')
    return true
  } else {
    input.addClass('error')
    return false
  }
}
var checkRequired = function (input) {
  if (input.val() || !input.empty()) {
    input.removeClass('error')
    return true
  } else {
    input.addClass('error')
    return false
  }
}
var checkAccept = function (input) {
  console.log(input)
  if (input.is(':checked')) {
    input.removeClass('error')
    return true
  } else {
    input.addClass('error')
    return false
  }
}
const formValidation = () => {
  const form = $('.js-valid-form')

  form.length > 0 &&
    form.each(function () {
      var thisForm = $(this)
      const mail = $(this).find('[type=email]')
      const required = $(this).find('[data-required=required]')
      const checkbox = $(this).find('.required[type=checkbox]')
      const select = $(this).find('.select-modal-form-js')
      const formButton = $(this).find('[type=submit]')

      mail.length > 0 && mail.on('focusout', (e) => checkEmail(mail))

      required.length > 0 &&
        required.focusout(function () {
          let input = $(this)
          checkRequired(input)
        })
      checkbox.length > 0 &&
        checkbox.change(function () {
          let input = $(this)
          checkAccept(input)
          if (mail.length > 0) checkEmail(mail)
        })
      formButton.click(function (e) {
        var checkAllRequired = function () {
          required.each(function () {
            var self = $(this)
            checkRequired(self)
          })
        }

        var checkAllAccept = function () {
          checkbox.each(function () {
            var self = $(this)
            checkAccept(self)
          })
        }

        checkAllRequired()
        checkAllAccept()
        checkSelect(select)
        if (mail.length > 0) checkEmail(mail)

        if (thisForm.find('.error').length > 0) {
          thisForm.find('.error:first').focus()
          return false
        } else {
          e.preventDefault()
          step4.length > 0 &&
            step4.addClass('is-active').siblings().removeClass('is-active')
          eServiceEmailForm.removeClass('is-active')
          eServiceNumberForm.removeClass('is-active')
          productForm.removeClass('is-active')
          finishedForm.addClass('is-active')
          return true
        }
      })
    })
}

export default formValidation
