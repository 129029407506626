
const searchBar = () => {
	const $openSearch = $('.search-box-visible');
	const $searchForm = $('.main-search-form');
	const $searchClose = $searchForm.find(".icon-close");
	const searchBarClose = $(".c-search-bar__close-ajax")


	$openSearch.click(function (e) {
		e.preventDefault();
		$openSearch.siblings().find(".inputT").focus();
		$searchForm.addClass("show");			
	});
	$searchClose.click(function (e) {
		$(this).closest(".main-search-form:not(.position-static)").removeClass("show");			
	});

	searchBarClose.click(function() {
		$(this).siblings(".dynamicWrapper").addClass('hide');
		$(this).siblings("input").val("");
	})
	$(".c-search-bar__input").on('change keyup', function() {
		$(this).siblings(".dynamicWrapper").removeClass('hide');
	})

}

export default searchBar;

// const $openSearch = $( '.search-box-visible' );
// const $searchForm = $( '.main-search-form' );


// $openSearch.click(function (e) {
// 	e.preventDefault();
// 	$searchForm.find(".inputT").focus();
// 	$searchForm.addClass("show");			
// });
// $searchForm.each(function() {
// 	const $searchClose = $(this).find(".icon-close");
// 	$searchClose.click(function (e) {
// 		console.log('x')
// 		$(this).removeClass("show");			
// 	});
// })