import {
  checkMobile,
  hamburger,
  footerMenu,
  aboutUsText,
  stickyMainMenu,
  loadLessButtonScroll,
  scrollToSection,
  removeVideoCovering,
  playVideo,
  readMore,
  scrollTopIcon,
  articleFloatingWindows,
  linksDisabled,
  setHeightPostHeader,
} from "./site";
// import modalAjax from './modal-ajax'
import pinchZoom from "./pinch";
import ClientsMenu from "./clientsMenu/clientsMenu";
import videoSection from "./video-yt";
import { videoSectionCarousel } from "./video-yt-slider.js";
import { benefitsSectionSlider } from "./benefits-slider.js";
import modal from "./modal";
import createMap from "./maps";
import searchBar from "./searchBar";
import dynamicSearch from "./dynamic-search";
import PostsSort from "./postsSorts/postsSorts";

import selectInModal from "./select/allSelect";

import { featuresSliders } from "./sliders/index";
import newsletterIcon from "./newlettericon/newsletterIcon";
import formValidation from "./forms/formValidation";

import { modalForm, modalSteps } from "./forms/modalForm";

import clientsModals from "./clientsModals/clientsModals";
import customScrollBar from "./customScroll/customScroll";
import ClientsToggle from "./clientsToggle/clientsToggle";
import trigerClientsMenu from "./clientsToggle/trigerClientsMenu";

import Accordion from "./accordion/accordion";
import CSBCalculator from './calculator-csb/calculator-csb';
import { calculatorMSC } from "./calculator-csb/calculator-msc.js";
import { calculatorWaas } from "./calculator-csb/calculator-new.js";
import { nis } from "./nis/nis.js";

import {
  checkIfEmptyInput,
  toggleNewsletter,
  telephonyForm,
} from "./forms/forms";

import { SectionsMenu } from "./sectionsMenu/sectionsMenu";

import {
  tabToggle,
  moreBtn,
  advantagesSlider,
  newsSlider,
  basicSlider,
  setColumnHeight,
  setImgToMiddle,
  popupSlider,
  accordionProduct,
  showAllItems,
  changeVideoPadding,
  tableComparison,
  resizeStickyMenuItems,
  subproductsToggle
} from "./product/product";
import SearchResults from "./search-results/search-results";
import Comparison from "./comparison/comparison";
import BrowserList from "./browserList/browserList";
import { gtmDatalayerPushes } from "./gtm-datalayer-pushes/gtm-datalayer-pushes.js";
import { clickViaUrlParam } from "./click-via-url-param/click-via-url-param.js";

// Leady z eService task 372
import EserwisLogged from "./eserwisLogged";

$(document).ready(() => {
  checkMobile();
  hamburger();
  footerMenu();
  // modalAjax()
  videoSection();
  aboutUsText();
  linksDisabled();
  featuresSliders();
  stickyMainMenu();
  loadLessButtonScroll();
  new ClientsMenu();
  nis();
  playVideo();
  clientsModals();
  scrollToSection();
  newsletterIcon();
  customScrollBar();
  removeVideoCovering();
  selectInModal();
  readMore();
  modalForm();
  newsSlider();
  basicSlider();
  modal();
  checkIfEmptyInput();
  modalSteps();
  formValidation();
  toggleNewsletter();
  telephonyForm();
  new SectionsMenu();
  searchBar();
  tabToggle();
  moreBtn();
  advantagesSlider();
  popupSlider();
  new ClientsToggle();
  scrollTopIcon();
  articleFloatingWindows();
  accordionProduct();
  setColumnHeight();
  showAllItems();
  changeVideoPadding();
  tableComparison();
  new PostsSort();
  setImgToMiddle();
  resizeStickyMenuItems();
  new Accordion();
  trigerClientsMenu();
  new SearchResults();
  pinchZoom();
  setHeightPostHeader();
  createMap();
  gtmDatalayerPushes();
  CSBCalculator();
  calculatorMSC();
  calculatorWaas();
  subproductsToggle();
  // Leady z eService task 372
  new EserwisLogged();
});
$(window).on("load", () => {
  new BrowserList();
  dynamicSearch();
  pinchZoom();
  new Comparison();
  clickViaUrlParam();
  videoSectionCarousel();
  benefitsSectionSlider();
});
$(window).on("resize", () => {
  checkMobile();
  hamburger();
  setHeightPostHeader();
  moreBtn();
  subproductsToggle();
});

//Contact Page
var getTextPrimary = $("#btn-toggle").text();
$("#btn-toggle").click(function () {
  var getNumber = $("#number-toggle").text();
  if ($(this).text() == getTextPrimary) {
    $(this).text(getNumber);
  } else {
    $(this).text(getTextPrimary);
  }
});

//Slick carousel
$(".slider-logos-custom").slick({
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

//Slick carousel
$(".slider-video-cyber").slick({
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
});

let subVideoCyber;
$(".slider-video-cyber").on(
  "beforeChange",
  function (event, slick, currentSlide, nextSlide) {
    subVideoCyber = $(".slider-video-cyber .js-box-video").eq(nextSlide).find(".js-sub-video-cyber--source")[0].innerHTML;
    $(".cyber-heading-wrap .js-sub-video-cyber--target")[0].innerHTML = subVideoCyber;
  }
    
);

$(document).ready(function () {
  $(".first-slide").click(function () {
    let video = '<iframe src="' + $(this).attr("data-video") + '"></iframe>';
    $(this).replaceWith(video);
  });

  if ( $("section.js-ipd").length > 0 ) {
    const boxes = $(".ipd .box");
    const boxesContent = $(".js-ipd-box-content");
    const openButtons = $(".js-ipd-button");
    const closeButtons = $(".js-ipd-close");
    openButtons.on("click", function () {
      boxes.removeClass("active");
      boxesContent.addClass("d-none");
      $(this).closest(".box").addClass("active");
      $("#" + $(this).data("show")).removeClass("d-none")
    });
    closeButtons.on("click", function () {
      boxes.removeClass("active");
      boxesContent.addClass("d-none");
    });
  }

  var envelopeText = $("span.envelope-text").html();
  const envelopedelay = $("span.envelope-delay").html();
  let userHasScrolled = false;
  // console.log("Envelope delay " + envelopedelay);

  window.addEventListener("scroll", () => {
    userHasScrolled = true;
    blobCall();
  });

  setTimeout(function () {
    blobCall();
  }, envelopedelay);

  function blobCall() {
    if (userHasScrolled == true) {
      $(".fixed-form-modal-box__label span").replaceWith("<span>...</span>");
      $(".fixed-form-modal-box__label").removeClass("active-blob");
    } else {
      setTimeout(function () {
        $(".fixed-form-modal-box__label span").replaceWith(
          "<span>" + envelopeText + "</span>"
        );
      }, 200);
      $(".fixed-form-modal-box__label").addClass("active-blob");
    }
  }

  $(".fixed-form-modal-box").on({
    mouseenter: function () {
      setTimeout(function () {
        $(".fixed-form-modal-box__label span").replaceWith(
          "<span>" + envelopeText + "</span>"
        );
      }, 200);
      $(".fixed-form-modal-box__label").addClass("active-blob");
    },
    mouseleave: function () {
      $(".fixed-form-modal-box__label span").replaceWith("<span>...</span>");
      $(".fixed-form-modal-box__label").removeClass("active-blob");
    },
  });
});
