import { Slider, SliderMD } from "./slider";
const sliders = $("[data-elem='featured-slider']");
const slidersMD = $("[data-elem='featured-slider-md']");

export const featuresSliders = () => {
	sliders.each(
		(i, v) => {
			new Slider( $( v ), {
				variableWidth: true,
				infinite: false
			} );
		}
	);
	slidersMD.each(
		(i, v) => {
			new SliderMD( $( v ), { variableWidth: true, infinite: false } );
		}
	);
};
