class ClientsToggle {
  constructor() {
    this.triggers = document.querySelectorAll('.js-toggle-clients');

    this.triggerClickHandler = this.triggerClickHandler.bind(this);

    this.init();
  }

  triggerClickHandler(evt) {
    evt.preventDefault();
    const wrapper = document.querySelector('.js-wrapper-clients');
    const listHeight = wrapper.querySelector('ul').clientHeight;

    wrapper.classList.add('o-section-our-clients__second-list--active');
    wrapper.style.maxHeight = `${listHeight}px`;
    evt.target.style.display = 'none';
  }

  init() {
    this.triggers.forEach((item) => {
      item.addEventListener('click', this.triggerClickHandler)
    })
  }
}

export default ClientsToggle;
