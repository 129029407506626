const modalAjax = (wrapper = document) => {	
	const triggers = wrapper.querySelectorAll("[data-modal-open]");
	const elemOpen = $(wrapper);

	elemOpen.each(function () {			
		const dataEL = $(this).attr("data-modal-open");
		const El = $("[data-modal='" + dataEL +"']");
		const ElClose = $("[data-modal-close='" + dataEL +"']");

	});	
	triggers.forEach((trigger) => {		
		if (trigger) {
			trigger.addEventListener("click", (e) => {
				e.preventDefault();
				const dataEL = trigger.getAttribute("data-modal-open");
				const El = document.querySelector("[data-modal='" + dataEL +"']");
				const ElClose = document.querySelector("[data-modal-close='" + dataEL +"']");
				El.classList.add("is-active");
			    $('body').addClass('open-modal');
				ElClose.addEventListener("click", (e) => {
					e.preventDefault();
					El.classList.remove("is-active");
			    	$('body').removeClass('open-modal');
				});
			});

		}
	  });  

};

export default modalAjax;